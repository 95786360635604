body {
  font-weight: lighter;
}

.heading {
  text-transform: uppercase;
  font-weight: normal;
}

.justified {
  text-align: justify;
  text-align-last: left;
}

h1, h2 {
  text-transform: uppercase;
}


.btn-default {
  padding: 10px 30px;
  &:focus,
  &.focus {
    background-color: @orange;
    color: white;
  }
  &:hover {
    background-color: @orange;
    color: white;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-color: @orange;
    color: white;

    &:hover,
    &:focus,
    &.focus {
      background-color: @orange;
      color: white;
    }
  }
}

header {
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
}

.navbar-header {
  float: none;
  .navbar-toggle {
    float: left;
    left: 20px;
    top: 15px;
  }
}

.header-logo {
  width: 50%;
  margin: 12px auto;
}

.navbar-nav {
  .heading;
  font-size: 2.5rem;

  li.selected {
    a {
      color: @orange;
    }
  }
}

.footer {
  padding-top: 80px;
  text-align: center;
  a:not(.btn) {
    text-decoration: underline;
  }
  a.btn {
    margin-bottom: @padding-base-vertical;
  }
}

.social-icons {
  font-size: 4rem;
  .hidden-print;
  ul > li {
    padding: 0;
  }
}

.copyright {
  color: #BBB;
  font-size: 1.5rem;
}

.top-link {
  position: absolute;
  right: 30px;
  top: 0;
  font-size: 1.5em;
  margin-top: -0.25em;
  .hidden-print;
}

.below-header {
  margin-top: @header-height;
}

.below-reference-filters {
  margin-top: (@header-height + @reference-filters-height);
}

.below-carousel {
  margin-top: 40px;
}

.main-copy {
  /*.make-xs-column(12);

  .make-sm-column(10);
  .make-sm-column-offset(1);

  .make-md-column(8);
  .make-md-column-offset(2);*/

  h1 {
    .heading;
    font-size: @copy-h1-size;
  }
  h2 {
    font-size: @copy-h2-size;
  }

  a:not(.btn) {
    text-decoration: underline;
  }
}

.carousel-wrapper {
  margin-bottom: 35px;
  .hidden-print;
}

.carousel-image {
  height: 100vh;
  margin-top: -@carousel-bar-height;
  background: no-repeat center;
  background-size: cover;
}

.carousel-indicators {
  display: none;
}

.carousel-caption {
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.6em;
  padding: 0;
  bottom: 10vh;
  text-shadow: none;

  p {
    // https://css-tricks.com/multi-line-padded-text/#article-header-id-4
    margin: 0;
    display: inline;
    background-color: @orange;
    box-decoration-break: clone;
    padding: 0.6rem;
  }
}

.carousel-control {
  &.left,
  &.right {
    background-image: none;
    filter: none;
  }
}

/* Vertically center controls within carousel */
.icon-prev,
.icon-next,
.glyphicon-chevron-left,
.glyphicon-chevron-right {
  margin-top: (@header-height / 2 - @carousel-bar-height / 2 - 10px) !important;
}

.carousel-lower-bar {
  height: @carousel-bar-height;
  background-color: @orange;
  text-align: center;
  a {
    color: white;
  }
  .fa {
    line-height: @carousel-bar-height;
    font-size: @carousel-bar-height;
  }
}

.tiles-wrapper {
  overflow: hidden;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  .tile {
    position: relative;
    cursor: pointer;
  }
}

.tiles-checkerboard {
  .tiles-pair {
    display: flex;
  }
}

.tiles-centered {
  justify-content: center;
  .tile {
    margin: (@card-padding / 2);
  }
}

.tile-content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column !important;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  padding: 20px;
  font-size: 3rem;
  line-height: 0.8em;
  overflow: hidden;
  h2 {
    margin: 12.5px 0;
    font-size: 1.5em;
  }
}

.tile-reference,
.tile-team-member {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .tile-content {
    opacity: 0;
    padding-top: 40px;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover {
    .tile-content {
      opacity: 1;
      padding-top: 20px;
    }
  }
}

.tile-team-member {
  .tile-content {
    background-color: @orange;
    color: white;
  }
}

.reference-filters {
  .heading;
  background-color: @body-bg;
  min-height: @reference-filters-height;
  text-align: center;
  border-top: 3px solid @orange;
  padding-top: 15px;
}

.reference-tag {
  color: lighten(@gray-dark, 20%);
}

.news-list {
  display: flex;
  flex-wrap: wrap;
}

.news-item {
  .make-xs-column(12);
  padding: (@card-padding / 2);
  display: flex;
  align-items: stretch;
}

.news-meta,
.news-detail-meta {
  font-weight: lighter;
  .fa {
    font-size: 1.5em;
  }
}

.news-image {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 25%;
}

.news-content {
  width: 100%;
  background: white;
  padding: 20px;

  h2 {
    font-size: 1.5em;
  }

  a:hover {
    text-decoration: underline;
    color: inherit;
  }
}

.news-lead {
  .justified;
  p {
    display: inline;
  }
}

.news-more {
  font-weight: normal;
}

.news-item-imageless {
  .make-xs-column(12);
  .make-md-column(6);
  padding: (@card-padding / 2);

  .news-content {
    width: 100%;
  }
}

.news-detail-meta {
  .heading;
  text-align: center;
  color: @orange;
  a {
    color: @orange;
  }
  ul > li {
    padding-left: 20px;
  }
}

.news-pagination {
  .heading;
  font-size: 0.9em;
  text-align: center;
  margin-top: 20px;
  .hidden-print;
}

.team-member-name {
  text-align: left;
  h1 {
    margin-top: 0;
    font-size: @copy-h1-size;
  }
}

.team-member-role,
.team-member-description {
  p {
    .justified;
  }
}

.team-member-role {
  font-size: @copy-h1-size;
  line-height: 24px;
}

.team-member-links {
  text-align: right;
}

.team-member-portrait {
  img {
    max-width: 400px;
  }
}

#contact-map {
  height: 100vh;
  margin-top: -@carousel-bar-height;
}

.client-list {
  [class*=col]  {
    padding: (@card-padding / 2);
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: (@screen-sm-min - 1)) {
  .navbar-header {
    height: @header-height-small;
  }

  /* 1 tile per row */
  .tiles-fullwidth {
    .tile {
      width: 100vw;
      height: 100vw;
      .tile-content {
        font-size: 5vw;
      }
    }
  }
  .tiles-centered {
    .tile {
      width: 70vw;
      height: 70vw;
      .tile-content {
        font-size: 5vw;
      }
    }
  }
  .tiles-checkerboard {
    .tiles-pair {
      flex-direction: column;
    }
  }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {

  .navbar {
    height: @header-height;
    &.smaller {
      height: @header-height-small;
      transform-style: preserve-3d;  /* Keep logo clickable */
    }
  }

  .header-logo {
    width: 400px;
    margin: @header-margin-top auto;
    transition: all 0.3s;
    &.smaller {
      /* Manually adjusted */
      transform: translate3d(-245px, 0, 1px);
      margin: 20px auto;
      width: 240px;
    }
  }

  .navbar-nav {
    margin: 0 auto;
    width: 100%;
    transition: all 0.3s;

    li {
      display: inline-block;
      float: none;
    }
  }

  .navbar-collapse.smaller {
    /* Manually adjusted */
    transform: translate(150px, -60px);
    .navbar-nav {
      font-size: 21px;
      > li > a {
        padding: 10px 10px;
      }
    }
  }

  /* 2 tiles per row */
  .tiles-fullwidth {
    width: 100vw;
    .tile {
      width: 50vw;
      height: 50vw;
      .tile-content {
        font-size: 2.5vw;
      }
    }
    &.tiles-checkerboard {
      div:nth-child(2n+2) {
        flex-direction: row-reverse;
      }
    }
  }
  /* 4 + 3 */
  .tiles-centered {
    .tile {
      width: 20%;
      height: 0;
      padding-bottom: 20%;
      .tile-content {
        font-size: 1.2vw;
      }
    }
  }

  .reference-filters {
    ul {
      transition: all 0.3s;
    }
    &.smaller {
      ul {
        transform: translateY(-15px);
      }
    }
  }

  .team-member-copy {
    margin-top: 20px;
  }

  .team-member-portrait {
    img {
      width: 90%;
    }
  }

  /* Sticky footer */
  .footer {
    position: absolute;
    bottom: 0;
    height: @footer-height;
    width: 100%;
  }
  body {
    margin-bottom: @footer-height;
  }
  html {
    position: relative;
    min-height: 100%;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
  /* 4 tiles per row */
  .tiles-fullwidth {
    .tile {
      width: 25vw;
      height: 25vw;
      .tile-content {
        font-size: 1.5vw;
      }
    }
    &.tiles-checkerboard {
      div:nth-child(4n+1),
      div:nth-child(4n+2) {
        flex-direction: row;
      }
      div:nth-child(4n+3),
      div:nth-child(4n+4) {
        flex-direction: row-reverse;
      }
    }
  }

  .news-image {
    /* Quadratic */
    height: 0;
    padding-bottom: 25%;
    flex-shrink: 0;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {

  .header-logo {
    &.smaller {
      /* Manually adjusted */
      transform: translate3d(-300px, 0, 1px);
    }
  }

  .navbar-nav {
    &.smaller {
      /* Manually adjusted */
      transform: translate(225px, -60px);
      font-size: 24px;
      > li > a {
        padding: 10px 16px;
      }
    }
  }

  .carousel-caption {
    font-size: 3.5rem;
  }
}

@media print {
  * {
    transition: none !important;
  }

  .footer,
  .news-title {
    a[href]:after {
        content: '';  // Overriding bootstrap/less/print.less#23
    }
  }

  .cms,
  header {
    display: none;
  }

  .below-header,
  .below-reference-filters {
    margin-top: 0;
  }

  .tile {
      height: 200px !important;
      .tile-content {
        font-size: inherit !important;
      }
  }

  .tile-reference .tile-content,
  .tile-team-member .tile-content {
    opacity: 1;
  }

  .team-member-name,
  .team-member-links {
    .make-xs-column(12);
  }

  .team-member-links {
    text-align: right;
  }
}
